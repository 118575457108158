import React, { FC, useEffect, useRef, useState } from 'react';
import { getConfig } from '../../../config/config';
import { useAppSelector } from 'store';
import { useLanguage } from 'languages/languageContext';

const { theme } = getConfig();

export const CollapsedPost: FC<{
  handleCollapse: () => void;
  type: 'html' | 'text';
  id: string;
  collapsed: boolean;
  children?: React.ReactNode;
  customHeight?: number;
}> = (props) => {
  const { handleCollapse, children, type, id, collapsed, customHeight } = props;
  const { global } = useLanguage();
  const { html_preview_height } = useAppSelector((state) => state.common);
  const initialHeight = customHeight || 15;

  const neededHeight = type === 'html' ? html_preview_height : initialHeight;

  const [viewHeight, setViewHeight] = useState({ height: 0, disabled: false });
  const measureView = () => {
    if (viewHeight.disabled) return;
    const divHeight = document?.getElementById(`collapsable_${id}`)?.offsetHeight;
    setViewHeight({ disabled: true, height: divHeight });
  };

  useEffect(() => {
    measureView();
  }, []);

  const vhToPixels = (vhValue) => {
    const pixelValue = (vhValue * window.innerHeight) / 100;
    return pixelValue;
  };

  const wHeight = vhToPixels(neededHeight);
  const height = collapsed && viewHeight.height > wHeight ? wHeight : undefined;

  const wrapperStyle = { position: 'absolute', width: '100%', bottom: 65, paddingBottom: 20 };

  return (
    <div
      id={`collapsable_${id}`}
      style={{
        height,
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {children}
      {viewHeight.height > wHeight && collapsed && (
        //@ts-ignore
        <div style={wrapperStyle}>
          <BlurLikeView />
          <span
            style={{
              position: 'absolute',
              bottom: 0,
              height: 32,
              marginLeft: 20,
              width: '100%',
              backgroundColor: theme.BACKGROUND,
              textDecoration: 'none',
              cursor: 'pointer',
              color: '#2671D1',
            }}
            onClick={handleCollapse}
          >
            {global.showMore}
          </span>
        </div>
      )}
    </div>
  );
};

const BlurLikeView = () => {
  return (
    <>
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          height: 45,
          width: '100%',
          backgroundColor: `${theme.BACKGROUND}`,
          opacity: 0.2,
        }}
      />
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          height: 44,
          width: '100%',
          backgroundColor: `${theme.BACKGROUND}`,
          opacity: 0.2,
        }}
      />
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          height: 43,
          width: '100%',
          backgroundColor: `${theme.BACKGROUND}`,
          opacity: 0.2,
        }}
      />
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          height: 42,
          width: '100%',
          backgroundColor: `${theme.BACKGROUND}`,
          opacity: 0.2,
        }}
      />
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          height: 41,
          width: '100%',
          backgroundColor: `${theme.BACKGROUND}`,
          opacity: 0.2,
        }}
      />
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          height: 40,
          width: '100%',
          backgroundColor: `${theme.BACKGROUND}`,
          opacity: 0.2,
        }}
      />
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          height: 39,
          width: '100%',
          backgroundColor: `${theme.BACKGROUND}`,
          opacity: 0.2,
        }}
      />
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          height: 38,
          width: '100%',
          backgroundColor: `${theme.BACKGROUND}`,
          opacity: 0.2,
        }}
      />
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          height: 37,
          width: '100%',
          backgroundColor: `${theme.BACKGROUND}`,
          opacity: 0.3,
        }}
      />
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          height: 36,
          width: '100%',
          backgroundColor: `${theme.BACKGROUND}`,
          opacity: 0.35,
        }}
      />
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          height: 35,
          width: '100%',
          backgroundColor: `${theme.BACKGROUND}`,
          opacity: 0.37,
        }}
      />
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          height: 34,
          width: '100%',
          backgroundColor: `${theme.BACKGROUND}`,
          opacity: 0.4,
        }}
      />
    </>
  );
};
